import { Ref, ref, watch, WatchOptions, WatchCallback } from "vue"

/** Makes watcher that can be easily paused */
export const usePausableWatch = <T>(source: Ref<T>, callback: WatchCallback<T, T | undefined>, options?: WatchOptions) => {
  const isPaused = ref(false)
  const pause = () => isPaused.value = true
  const resume = () => isPaused.value = false

  watch(source, (...args) => {
    if (!isPaused.value) {
      callback(...args)
    }
  }, options)

  const withPause = (fn: () => void) => {
    pause()
    fn()
    resume()
  }

  return {
    isPaused,
    pause,
    resume,
    withPause,
  }
}